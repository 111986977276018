<template>
  <div class="pa-4 ems-opacity-50-bg">
    <v-row>
      <v-col cols="auto">
        <div class="grey--text">基本電費</div>
        <v-skeleton-loader
          class="ml-4"
          type="heading"
          v-if="realtime.loading"
        ></v-skeleton-loader>
        <div
          v-else-if="realtime.data"
          class="ml-4 accent--text font-weight-bold text-h4"
        >
          {{ numeral(realtime.data.price).format('$0,0') }}
        </div>
      </v-col>
      <v-col cols="auto">
        <div class="grey--text">本月累計電度</div>
        <v-skeleton-loader
          class="ml-4"
          type="heading"
          v-if="realtime.loading"
        ></v-skeleton-loader>
        <div v-else class="ml-4 accent--text font-weight-bold text-h4">
          <span v-if="realtime.data">{{
            numeral(realtime.data.energy).format('0,0')
          }}</span>
          <span class="subtitle-1 pl-1">kWh</span>
        </div>
      </v-col>
      <v-col cols="auto">
        <div class="grey--text">功率</div>
        <v-skeleton-loader
          class="ml-4"
          type="heading"
          v-if="realtime.loading"
        ></v-skeleton-loader>
        <div v-else class="ml-4 accent--text font-weight-bold text-h4">
          <span v-if="realtime.data">{{
            numeral(realtime.data.power).format('0,0')
          }}</span>
          <span class="subtitle-1 pl-1">kW</span>
        </div>
      </v-col>
      <v-col cols="12" class="pa-0"> </v-col>
      <v-col cols="12" sm="6">
        <v-card elevation="0" outlined class="fill-height">
          <v-card-subtitle class="pb-0">本月最大需量</v-card-subtitle>
          <v-card-text>
            <v-skeleton-loader
              type="text"
              v-if="realtime.loading"
            ></v-skeleton-loader>
            <v-row v-else align="baseline" justify="space-between">
              <v-col cols="auto">
                <div
                  class="accent--text font-weight-bold text-h5"
                  v-if="realtime.data && realtime.data.maxDemand"
                >
                  {{ numeral(realtime.data.maxDemand.demand).format('0,0.00')
                  }}<span class="subtitle-1 pl-1">kW</span>
                </div>
              </v-col>
              <v-col cols="auto">
                <div
                  class="accent--text"
                  v-if="
                    realtime.data &&
                    realtime.data.maxDemand &&
                    realtime.data.maxDemand.timestamp
                  "
                >
                  發生日期：{{
                    moment(realtime.data.maxDemand.timestamp).format(
                      'YYYY/MM/DD HH:mm'
                    )
                  }}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <template
            v-if="
              !realtime.loading &&
              realtime.data &&
              realtime.data.meterCapacity &&
              realtime.data.maxDemand
            "
          >
            <v-card-subtitle class="pb-0">契約容量</v-card-subtitle>
            <v-card-text>
              <v-progress-linear
                color="blue"
                background-color="accent darken-2"
                rounded
                :value="
                  Math.max(
                    Math.min(
                      (realtime.data.maxDemand.demand /
                        realtime.data.meterCapacity) *
                        100,
                      100
                    ),
                    0
                  )
                "
                height="10"
              ></v-progress-linear>
              <v-row align="baseline" justify="space-between">
                <v-col cols="auto">
                  <div class="accent--text font-weight-bold">
                    已達
                    {{
                      numeral(
                        realtime.data.maxDemand.demand /
                          realtime.data.meterCapacity
                      ).format('0,0.00%')
                    }}
                  </div>
                </v-col>
                <v-col cols="auto">
                  <div class="accent--text font-weight-bold">
                    {{ realtime.data.meterCapacity }} kW
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </template>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card elevation="0" outlined class="fill-height">
          <v-card-subtitle class="pb-0">與去年同期用電相比</v-card-subtitle>
          <v-card-text v-if="realtime.loading">
            <v-skeleton-loader type="text"></v-skeleton-loader>
          </v-card-text>
          <v-card-text v-else>
            <v-row align="baseline" justify="space-between">
              <v-col cols="auto">
                <div
                  class="font-weight-bold text-h5"
                  v-bind:class="{
                    'red--text': realtime.data.yoy >= 0,
                    'green--text': realtime.data.yoy < 0
                  }"
                  v-if="
                    realtime.data &&
                    (realtime.data.yoy || realtime.data.yoy === 0)
                  "
                >
                  {{ numeral(realtime.data.yoy * 100).format('0,0')
                  }}<span class="subtitle-1 pl-1">%</span>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-if="realtime.loading">
            <v-skeleton-loader class="mt-5" type="text"></v-skeleton-loader>
          </v-card-text>
          <v-card-text v-else class="mt-5">
            <v-progress-linear
              v-if="realtime.data"
              :color="realtime.data.yoy >= 0 ? 'red' : 'ems-aquamarine'"
              background-color="accent darken-2"
              rounded
              :value="
                realtime.data.yoy * 100 * (realtime.data.yoy >= 0 ? 1 : -1)
              "
              height="10"
            ></v-progress-linear>
            <v-row align="baseline" justify="space-between">
              <v-col cols="auto"> </v-col>
              <v-col cols="auto">
                <div class="accent--text font-weight-bold" v-if="realtime.data">
                  {{
                    numeral(realtime.data.energy * realtime.data.yoy).format(
                      '+0,0'
                    )
                  }}
                  kWh
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-skeleton-loader
          v-if="realtime.loading"
          type="image"
        ></v-skeleton-loader>
        <v-card v-else elevation="0" outlined class="fill-height">
          <v-row class="fill-height">
            <v-col cols="auto">
              <v-card-subtitle class="pb-0">需量</v-card-subtitle>
              <v-card-text>
                <v-row align="baseline" justify="space-between">
                  <v-col cols="auto">
                    <div
                      v-if="realtime.data"
                      class="accent--text font-weight-bold text-h5"
                    >
                      {{
                        numeral(realtime.data.immediateDemand).format('0,0.00')
                      }}<span class="subtitle-1 pl-1">kW</span>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-subtitle class="pb-0 mt-5">需量預測</v-card-subtitle>
              <v-card-text>
                <v-row align="baseline" justify="space-between">
                  <v-col cols="auto">
                    <div
                      v-if="realtime.data"
                      class="accent--text font-weight-bold text-h5"
                    >
                      {{ numeral(realtime.data.predictDemand).format('0,0.00')
                      }}<span class="subtitle-1 pl-1">kW</span>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
            <v-col cols="auto" align-self="center" class="ma-auto">
              <v-card-text v-if="realtime.data && realtime.data.alarmcapacity">
                <v-progress-circular
                  :rotate="90"
                  :size="160"
                  :width="20"
                  :value="
                    (realtime.data.immediateDemand /
                      realtime.data.alarmcapacity) *
                    100
                  "
                  color="blue"
                  class="mt-2"
                >
                  <v-row>
                    <v-col cols="12" class="text-center pb-0">
                      <div>已達需量</div>
                    </v-col>
                    <v-col cols="12" class="text-center pt-0">
                      <div class="text-h4">
                        {{
                          numeral(
                            realtime.data.immediateDemand /
                              realtime.data.alarmcapacity
                          ).format('0.0%')
                        }}
                      </div>
                    </v-col>
                  </v-row>
                </v-progress-circular>
              </v-card-text>
              <v-card-text
                v-else-if="realtime.data && !realtime.data.alarmcapacity"
              >
                未設警戒值
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="6" sm="3">
        <v-skeleton-loader
          v-if="realtime.loading"
          type="image"
        ></v-skeleton-loader>
        <v-card v-else elevation="0" outlined class="fill-height">
          <v-card-subtitle class="pb-0">電壓</v-card-subtitle>
          <v-card-subtitle class="pb-0 pt-1">RS線</v-card-subtitle>
          <v-card-text class="py-0">
            <div
              v-if="realtime.data"
              class="accent--text font-weight-bold text-h6"
            >
              {{ numeral(realtime.data.rvoltage).format('0.0')
              }}<span class="subtitle-1 pl-1">v</span>
            </div>
          </v-card-text>
          <v-card-subtitle class="pb-0 pt-1">ST線</v-card-subtitle>
          <v-card-text class="py-0">
            <div
              v-if="realtime.data"
              class="accent--text font-weight-bold text-h6"
            >
              {{ numeral(realtime.data.svoltage).format('0.0')
              }}<span class="subtitle-1 pl-1">v</span>
            </div>
          </v-card-text>
          <v-card-subtitle class="pb-0 pt-1">TR線</v-card-subtitle>
          <v-card-text class="pt-0">
            <div
              v-if="realtime.data"
              class="accent--text font-weight-bold text-h6"
            >
              {{ numeral(realtime.data.tvoltage).format('0.0')
              }}<span class="subtitle-1 pl-1">v</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" sm="3">
        <v-skeleton-loader
          v-if="realtime.loading"
          type="image"
        ></v-skeleton-loader>
        <v-card v-else elevation="0" outlined class="fill-height">
          <v-card-subtitle class="pb-0">電流</v-card-subtitle>
          <v-card-subtitle class="pb-0 pt-1">R相</v-card-subtitle>
          <v-card-text class="py-0">
            <div
              v-if="realtime.data"
              class="accent--text font-weight-bold text-h6"
            >
              {{ numeral(realtime.data.rcurrent).format('0.0')
              }}<span class="subtitle-1 pl-1">A</span>
            </div>
          </v-card-text>
          <v-card-subtitle class="pb-0 pt-1">S相</v-card-subtitle>
          <v-card-text class="py-0">
            <div
              v-if="realtime.data"
              class="accent--text font-weight-bold text-h6"
            >
              {{ numeral(realtime.data.scurrent).format('0.0')
              }}<span class="subtitle-1 pl-1">A</span>
            </div>
          </v-card-text>
          <v-card-subtitle class="pb-0 pt-1">T相</v-card-subtitle>
          <v-card-text class="pt-0">
            <div
              v-if="realtime.data"
              class="accent--text font-weight-bold text-h6"
            >
              {{ numeral(realtime.data.tcurrent).format('0.0')
              }}<span class="subtitle-1 pl-1">A</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {
    meter: {
      type: Object,
      default: () => {}
    },
    loadingMeter: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      realtime: {
        loading: false,
        data: null
      }
    }
  },
  computed: {},
  watch: {
    meter(val) {
      this.updateRealtimeData(val)
    },
    loadingMeter(val) {
      this.updateRealtimeLoading(val)
    }
  },
  created: function () {},
  mounted: function () {
    this.updateRealtimeData(this.meter)
    this.updateRealtimeLoading(this.loadingMeter)
  },
  destroyed() {},
  methods: {
    updateRealtimeData(val) {
      this.realtime.data = val
    },
    updateRealtimeLoading(val) {
      this.realtime.loading = val
    }
  }
}
</script>

<style></style>
