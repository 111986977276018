<template>
  <v-card elevation="0" color="ems-opacity-50-bg">
    <v-skeleton-loader v-if="loadingMeter" type="image"></v-skeleton-loader>
    <v-card-text v-else>
      <div>
        <div>總用電量 （含新舊設備）</div>
        <div>
          <span class="blue--text font-weight-bold text-h5">{{
            numeral(totalEnergy).format('0,0')
          }}</span>
          <span class="pl-2">kWh</span>
        </div>
      </div>
      <div class="mt-5">
        <v-row align="center">
          <v-col cols="12" class="pb-0">
            <v-icon color="blue">mdi-circle-medium</v-icon
            ><span>冷氣總表</span>
            <v-icon color="ems-aquamarine">mdi-circle-medium</v-icon
            ><span>學校總表</span>
          </v-col>
          <v-col cols="auto" class="pt-0 pr-0">
            <v-icon color="blue" class="ml-5">mdi-power-plug</v-icon>
          </v-col>
          <v-col class="pt-0">
            <v-progress-linear
              :value="(totalACMETEREnergy / totalEnergy) * 100"
              color="blue"
              height="2rem"
              rounded
              background-color="ems-aquamarine"
            >
              <template v-slot:default>
                <v-row justify="space-between" class="mx-0 white--text">
                  <v-col cols="auto">
                    <span
                      >{{ numeral(totalACMETEREnergy).format('0,0') }} kWh</span
                    >
                  </v-col>
                  <v-col cols="auto">
                    <span
                      >{{
                        numeral(totalSCHOOLMETEREnergy).format('0,0')
                      }}
                      kWh</span
                    >
                  </v-col>
                </v-row>
              </template>
            </v-progress-linear>
          </v-col>
        </v-row>
        <v-row align="center" v-if="totalSOLARMETERMeterCapacity">
          <v-col cols="auto" class="pt-0 pr-0">
            <v-icon color="orange" class="ml-5">mdi-battery-charging</v-icon>
          </v-col>
          <v-col class="pt-0">
            <v-progress-linear
              color="orange"
              background-color="transparent"
              rounded
              :value="
                (totalSOLARMETEREnergy / totalSOLARMETERMeterCapacity) * 100
              "
              height="2rem"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </div>
      <div class="mt-5" v-if="totalSOLARMETEREnergy">
        <div>總發電量</div>
        <div>
          <span class="orange--text font-weight-bold text-h5">{{
            numeral(totalSOLARMETEREnergy).format('0,0')
          }}</span>
          <span class="pl-2">kWh</span>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {
    schoolId: {
      type: String,
      required: true
    },
    propsMeters: {
      type: Array,
      default: () => []
    },
    loadingMeter: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      meters: null
    }
  },
  computed: {
    totalEnergy() {
      if (!this.meters) {
        return null
      }
      return this.totalACMETEREnergy + this.totalSCHOOLMETEREnergy
    },
    totalACMETEREnergy() {
      if (!this.meters) {
        return null
      }
      return this.meters.reduce((accumulator, meter) => {
        if (meter.type === 'ACMETER') {
          return numeral(accumulator)
            .add(meter.energy || 0)
            .value()
        }
        return accumulator
      }, 0)
    },
    totalSCHOOLMETEREnergy() {
      if (!this.meters) {
        return null
      }
      return this.meters.reduce((accumulator, meter) => {
        if (meter.type === 'SCHOOLMETER') {
          return numeral(accumulator)
            .add(meter.energy || 0)
            .value()
        }
        return accumulator
      }, 0)
    },
    totalSOLARMETEREnergy() {
      if (!this.meters) {
        return null
      }
      return this.meters.reduce((accumulator, meter) => {
        if (meter.type === 'SOLARMETER') {
          return numeral(accumulator)
            .add(meter.energy || 0)
            .value()
        }
        return accumulator
      }, 0)
    },
    totalSOLARMETERMeterCapacity() {
      if (!this.meters) {
        return null
      }
      return this.meters.reduce((accumulator, meter) => {
        if (meter.type === 'SOLARMETER') {
          return numeral(accumulator)
            .add(meter.meterCapacity || 0)
            .value()
        }
        return accumulator
      }, 0)
    }
  },
  watch: {
    propsMeters(val) {
      this.meters = val
    }
  },
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {}
}
</script>

<style></style>
