<template>
  <v-card elevation="0" color="ems-opacity-50-bg">
    <v-skeleton-loader
      v-if="loadingMeter"
      type="card-heading"
    ></v-skeleton-loader>
    <v-card-text v-else>
      <div>
        <span class="pr-5">本月累計電費</span>
        <span class="accent--text font-weight-bold text-h5">{{
          numeral(totalPrice).format('$0,0')
        }}</span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {
    schoolId: {
      type: String,
      required: true
    },
    propsMeters: {
      type: Array,
      default: () => []
    },
    loadingMeter: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      meters: null
    }
  },
  computed: {
    totalPrice() {
      if (!this.meters) {
        return null
      }
      return this.meters.reduce((accumulator, meter) => {
        if (['ACMETER', 'SCHOOLMETER'].includes(meter.type)) {
          return accumulator + (meter.price || 0)
        }
        return accumulator
      }, 0)
    }
  },
  watch: {
    propsMeters(val) {
      this.meters = val
    }
  },
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {}
}
</script>

<style></style>
