<template>
  <v-card
    elevation="0"
    :img="data.wxCode ? WeathersWxImg[data.wxCode] : null"
    color="#abc0de"
    min-height="216"
    style="position: relative"
  >
    <v-card-text
      style="
        background-image: linear-gradient(
          180deg,
          rgb(0 0 0 / 50%),
          transparent 100px
        );
      "
    >
      <v-row class="white--text">
        <v-col cols="auto" style="z-index: 2">
          <v-card
            width="fit-content"
            style="border: 2px solid #fff; background-color: transparent"
          >
            <v-card-subtitle
              width="fit-content"
              class="pa-0 white--text"
              style="border-bottom: 2px solid #fff"
            >
              <div class="text-center">{{ moment().format('MMM') }}</div>
            </v-card-subtitle>
            <v-card-text class="white--text px-4 py-1">
              <span class="text-h5 font-weight-bold">{{
                moment().format('DD')
              }}</span>
            </v-card-text>
          </v-card>
          <div class="text-center font-weight-bold mt-1" v-if="lastUpdateTime">
            {{ lastUpdateTime.format('HH：mm') }}
          </div>
        </v-col>
        <template v-if="!noWeatherData">
          <v-col cols>
            <div v-if="data.wxChineseDescription">
              {{ data.wxChineseDescription }}
            </div>
            <div v-else class="error--text">維護中</div>
            <div v-if="data.temperature" class="text-h5 font-weight-bold">
              {{ parseInt(data.temperature) }}°C
            </div>
            <div v-else class="font-weight-bold error--text">維護中</div>
          </v-col>
          <v-col cols>
            <div>戶外濕度</div>
            <div class="text-h5 font-weight-bold" v-if="data.humidity">
              {{ (data.humidity * 100).toFixed() }}%
            </div>
            <div v-else class="font-weight-bold error--text">維護中</div>
          </v-col>
          <v-col cols>
            <div>風速</div>
            <div v-if="data.windSpeed" class="text-h5 font-weight-bold">
              {{ parseInt(data.windSpeed) }}級
            </div>
            <div v-else class="font-weight-bold error--text">維護中</div>
          </v-col>
          <v-col cols="12" class="pa-0"></v-col>
          <v-col cols="auto">
            <PM25Box v-model="data.pm25" />
          </v-col>
        </template>
      </v-row>
    </v-card-text>

    <NoWeatherBackground v-if="noWeatherData" />
  </v-card>
</template>

<script>
import Weathers from '@/api/ems/Weathers'
import { countdownReload } from '@/shared/utils'
import PM25Box from '@/components/PM25Box'
import NoWeatherBackground from '@/components/NoWeatherBackground'

export default {
  name: undefined,
  mixins: [],
  components: {
    PM25Box,
    NoWeatherBackground
  },
  props: {
    schoolId: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      WeathersWxImg: {
        1: require('@/assets/image/weather/1/cloud-blue-sky_S.jpg'),
        2: require('@/assets/image/weather/2.3.4/cloud-2294671_S.jpg'),
        3: require('@/assets/image/weather/2.3.4/cloud-2294671_S.jpg'),
        4: require('@/assets/image/weather/2.3.4/cloud-2294671_S.jpg'),
        5: require('@/assets/image/weather/5.6.7/black-rain-abstract-dark-power_S.jpg'),
        6: require('@/assets/image/weather/5.6.7/black-rain-abstract-dark-power_S.jpg'),
        7: require('@/assets/image/weather/5.6.7/black-rain-abstract-dark-power_S.jpg'),
        8: require('@/assets/image/weather/8-29/clouds-4793523_S.jpg'),
        9: require('@/assets/image/weather/8-29/clouds-4793523_S.jpg'),
        10: require('@/assets/image/weather/8-29/clouds-4793523_S.jpg'),
        11: require('@/assets/image/weather/8-29/clouds-4793523_S.jpg'),
        12: require('@/assets/image/weather/8-29/clouds-4793523_S.jpg'),
        13: require('@/assets/image/weather/8-29/clouds-4793523_S.jpg'),
        14: require('@/assets/image/weather/8-29/clouds-4793523_S.jpg'),
        15: require('@/assets/image/weather/15-34/thunderstorm-6313125_S.jpg'),
        16: require('@/assets/image/weather/15-34/thunderstorm-6313125_S.jpg'),
        17: require('@/assets/image/weather/15-34/thunderstorm-6313125_S.jpg'),
        18: require('@/assets/image/weather/15-34/thunderstorm-6313125_S.jpg'),
        19: require('@/assets/image/weather/19.21.30/dark-stormy-clouds_S.jpg'),
        20: require('@/assets/image/weather/8-29/clouds-4793523_S.jpg'),
        21: require('@/assets/image/weather/19.21.30/dark-stormy-clouds_S.jpg'),
        22: require('@/assets/image/weather/15-34/thunderstorm-6313125_S.jpg'),
        23: require('@/assets/image/weather/8-29/clouds-4793523_S.jpg'),
        24: require('@/assets/image/weather/24.25/fog-4735793_S.jpg'),
        25: require('@/assets/image/weather/24.25/fog-4735793_S.jpg'),
        26: require('@/assets/image/weather/26-28/frankfurt-4945405_S.jpg'),
        27: require('@/assets/image/weather/26-28/frankfurt-4945405_S.jpg'),
        28: require('@/assets/image/weather/26-28/frankfurt-4945405_S.jpg'),
        29: require('@/assets/image/weather/8-29/clouds-4793523_S.jpg'),
        30: require('@/assets/image/weather/19.21.30/dark-stormy-clouds_S.jpg'),
        31: require('@/assets/image/weather/31-39/clouds-fog-rain_S.jpg'),
        32: require('@/assets/image/weather/31-39/clouds-fog-rain_S.jpg'),
        33: require('@/assets/image/weather/31-39/clouds-fog-rain_S.jpg'),
        34: require('@/assets/image/weather/15-34/thunderstorm-6313125_S.jpg'),
        35: require('@/assets/image/weather/35-41/thunderstorm-fog-rain_S.jpg'),
        36: require('@/assets/image/weather/35-41/thunderstorm-fog-rain_S.jpg'),
        37: require('@/assets/image/weather/35-41/thunderstorm-fog-rain_S.jpg'),
        38: require('@/assets/image/weather/31-39/clouds-fog-rain_S.jpg'),
        39: require('@/assets/image/weather/31-39/clouds-fog-rain_S.jpg'),
        40: require('@/assets/image/weather/sunny.jpg'),
        41: require('@/assets/image/weather/35-41/thunderstorm-fog-rain_S.jpg'),
        42: require('@/assets/image/weather/42/snowfall-201496_S.jpg')
      },
      data: {},
      lastUpdateTime: null,
      timer: null
    }
  },
  computed: {
    noWeatherData() {
      return !Object.values(this.data).filter((value) => value).length
    }
  },
  watch: {},
  created: function () {},
  mounted: function () {
    this.getInfo()
    this.timer = countdownReload(this.getInfo, 60)
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    getInfo() {
      Weathers.school(this.schoolId).then((response) => {
        if (!response.data) {
          return
        }
        this.data = (({
          wxCode,
          wxChineseDescription,
          temperature,
          humidity,
          windSpeed,
          pm25
        }) => ({
          wxCode,
          wxChineseDescription,
          temperature,
          humidity,
          windSpeed,
          pm25
        }))(response.data)
        Object.keys(response.data).forEach((key) => {
          if (this.data[key] === '-99' || this.data[key] === '') {
            this.data[key] = null
          }
        })
        this.lastUpdateTime = moment()
      })
    }
  }
}
</script>
