<template>
  <div>
    <template v-if="loadingMeter">
      <v-tabs background-color="transparent" color="transparent">
        <v-tab disabled> </v-tab>
        <v-tab-item>
          <v-skeleton-loader
            type="list-item-two-line"
            height="100%"
            min-width="200px"
          ></v-skeleton-loader>
        </v-tab-item>
      </v-tabs>
    </template>
    <template v-else>
      <v-tabs
        v-model="bindTab"
        align-with-title
        center-active
        background-color="transparent"
        color="transparent"
      >
        <v-tab v-for="meter in filtersMeters">
          <div :key="meter.id">
            <div>
              {{ meter.name }}
            </div>
            <div class="text-right text-caption">
              {{ meter.serialNumber || 'null' }} |
              {{ meter.modbusId || 'null' }}
            </div>
          </div>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="bindTab">
        <v-tab-item v-for="meter in filtersMeters" :key="meter.id">
          <SOLARMETER
            v-if="meter.type === 'SOLARMETER'"
            :id="meter.id"
            :schoolId="schoolId"
          />
          <DafaultMETER
            v-else
            :meter="meters.find((m) => m.id === meter.id)"
            :loadingMeter="loadingMeter"
          />
        </v-tab-item>
      </v-tabs-items>
    </template>
  </div>
</template>

<script>
import DafaultMETER from './meterCard/DafaultMETER'
import SOLARMETER from './meterCard/SOLARMETER'

export default {
  name: undefined,
  mixins: [],
  components: {
    DafaultMETER,
    SOLARMETER
  },
  props: {
    schoolId: {
      type: String,
      required: true
    },
    meters: {
      type: Array,
      default: []
    },
    loadingMeter: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      showMeterType: ['SCHOOLMETER', 'ACMETER', 'SOLARMETER'],
      bindTab: null
    }
  },
  computed: {
    filtersMeters() {
      if (!this.meters) {
        return []
      }
      return this.meters
        .filter((meter) => this.showMeterType.includes(meter.type))
        .sort(
          (a, b) =>
            this.showMeterType.findIndex((v) => v === a.type) -
            this.showMeterType.findIndex((v) => v === b.type)
        )
    }
  },
  watch: {},
  filters: {
    showMeterName: function (value) {
      if (!value) return null
      switch (value) {
        case 'ACMETER':
          return '冷氣總表'
        case 'SCHOOLMETER':
          return '學校總表'
        case 'SOLARMETER':
          return '再生能源量測電表'
        default:
          return value
      }
    }
  },
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
*::v-deep {
  .v-tab {
    font-size: 1rem;
    background-color: #9fcfe9;
    border-radius: 10px 10px 0 0;
    color: #1a5878;
    padding: 0 2rem;
    &:not(:last-child) {
      margin-right: 5px;
    }
    &.v-tab--active {
      color: #fff;
      background-color: #1a5878;
    }
  }
  .v-tabs-items {
    background-color: transparent !important;
    border-radius: 0 10px 10px 10px;
  }
}
</style>
