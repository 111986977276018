<template>
  <v-card
    :to="`/class/${item.id}`"
    elevation="0"
    height="100%"
    width="100%"
    class="ems-opacity-50-bg"
  >
    <v-card-title class="font-weight-bold accent--text subtitle-1">
      {{ item.name }}
    </v-card-title>
    <template v-if="item.airConditioners">
      <v-row>
        <v-col cols="5" class="pt-0">
          <v-card-subtitle class="py-0 ems-blue-grey--text">
            運轉中的冷氣
          </v-card-subtitle>
          <div
            v-if="isIRClassroom"
            class="px-4 subtitle-1 accent--text d-flex align-center"
          >
            <v-img
              contain
              max-height="18"
              max-width="18"
              :src="require('@/assets/image/acControl/ir.png')"
            ></v-img>
            <span>IR 控制</span>
          </div>
          <div
            v-if="isEstablishedClassroom"
            class="px-4 subtitle-1 accent--text d-flex align-center"
          >
            <v-icon
              x-small
              :color="cardReaderIsOn ? 'success' : 'error'"
              class="mr-1"
            >
              mdi-checkbox-blank-circle
            </v-icon>
            <span>既設冷氣</span>
          </div>
          <div v-else class="px-4 subtitle-1 accent--text">
            <v-icon
              x-small
              :color="acState(item.airConditioners).on ? 'success' : 'error'"
            >
              mdi-checkbox-blank-circle
            </v-icon>
            {{ acState(item.airConditioners).on || 0 }} /
            {{ item.airConditioners.length }}
          </div>
        </v-col>
        <v-col cols="7" class="pt-0">
          <v-card-subtitle class="py-0 ems-blue-grey--text">
            所有電表當月累積電度
          </v-card-subtitle>
          <div class="px-4 subtitle-1 accent--text">{{ item.energy }} kWh</div>
        </v-col>
      </v-row>
      <v-divider class="my-2 mx-2"></v-divider>
      <v-row>
        <v-col cols="6">
          <v-card-subtitle class="py-0 ems-blue-grey--text">
            即時功率
          </v-card-subtitle>
          <div class="px-4 pb-2 subtitle-1 accent--text">
            {{ item.power }} kW
          </div>
        </v-col>
        <v-col cols="6" class="text-right text-body-2">
          <div class="py-0 ems-blue-grey--text text-caption px-4">
            資料更新時間
          </div>
          <div class="px-4 pb-2 text-caption accent--text">
            {{
              item.powerTs
                ? moment(item.powerTs).format('YYYY-MM-DD HH:mm')
                : '--'
            }}
          </div>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-card-subtitle class="py-3 ems-blue-grey--text">
        無冷氣資料
      </v-card-subtitle>
    </template>
  </v-card>
</template>

<script>
export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {}
  },
  computed: {
    isIRClassroom() {
      return !!this.item?.irAirConditioner?.length
    },
    isEstablishedClassroom() {
      // classroom type 1.NORMAL = 一般教室, 2. EXISTING_AIR_CON = 既設冷氣教室
      return this.item?.type === 'EXISTING_AIR_CON'
    },
    cardReaderIsOn() {
      return this.item?.cardReaders[0]?.relay?.status
    }
  },
  watch: {},
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {
    acState(item) {
      const on = item.reduce((count, d) => {
        if (d.status && d.status === 'POWER_ON') count++
        return count
      }, 0)
      return {
        on,
        off: item.length - on || 0,
        total: item.length
      }
    },
    reduceEnergyList(list) {
      return list.reduce((count, d) => (count = count + d.energy), 0)
    }
  }
}
</script>

<style></style>
