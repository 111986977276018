<template>
  <div class="pa-4 ems-opacity-50-bg">
    <v-row>
      <v-col cols="auto">
        <div class="grey--text">本月累計發電度</div>
        <v-skeleton-loader
          class="ml-4"
          type="heading"
          v-if="PV.loading"
        ></v-skeleton-loader>
        <div v-else class="ml-4 accent--text font-weight-bold text-h4">
          <span v-if="PV.data">{{
            numeral(PV.data.monthlyEnergy).format('0,0')
          }}</span>
          <span class="subtitle-1 pl-1">kWh</span>
        </div>
      </v-col>
      <v-col cols="auto">
        <div class="grey--text">發電功率</div>
        <v-skeleton-loader
          class="ml-4"
          type="heading"
          v-if="PV.loading"
        ></v-skeleton-loader>
        <div v-else class="ml-4 accent--text font-weight-bold text-h4">
          <span v-if="PV.data">
            {{ numeral(thePower).format('0,0') }}
          </span>
          <span class="subtitle-1 pl-1">kW</span>
        </div>
      </v-col>
      <v-col cols="12" class="pa-0"></v-col>
      <v-col cols="12" sm="6">
        <v-skeleton-loader v-if="PV.loading" type="image"></v-skeleton-loader>
        <v-card v-else elevation="0" outlined class="fill-height">
          <v-row class="fill-height">
            <v-col cols="12">
              <v-card-subtitle class="pb-0">本月最大發電功率</v-card-subtitle>
              <v-card-text>
                <v-row align="baseline" justify="space-between">
                  <v-col cols="auto">
                    <div
                      v-if="PV.data && PV.data.max"
                      class="accent--text font-weight-bold text-h5"
                    >
                      {{ numeral(PV.data.max.value).format('0,0.00') }}
                      <span class="subtitle-1 pl-1">kW</span>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-subtitle
                class="accent--text pt-0"
                v-if="PV.data && PV.data.max && PV.data.max.timestamp"
              >
                發生日期：{{
                  moment(PV.data.max.timestamp).format('YYYY/MM/DD HH:mm')
                }}
              </v-card-subtitle>
            </v-col>
            <v-col cols="auto" align-self="center" class="ma-auto">
              <v-card-text v-if="PV.data && PV.data.meterCapacity">
                <v-progress-circular
                  :rotate="270"
                  :size="200"
                  :width="20"
                  :value="(thePower / PV.data.meterCapacity) * 100"
                  color="orange"
                  class="mt-2"
                >
                  <v-row class="text--secondary">
                    <v-col cols="12" class="text-center pb-0">
                      <div>即時發電與<br />裝置容量佔比</div>
                    </v-col>
                    <v-col cols="12" class="text-center pt-0">
                      <div class="text-h4 font-weight-bold">
                        {{
                          numeral(thePower / PV.data.meterCapacity).format(
                            '0.0%'
                          )
                        }}
                      </div>
                    </v-col>
                  </v-row>
                </v-progress-circular>
              </v-card-text>
              <v-card-text v-else> 尚未設定裝置容量 </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-skeleton-loader v-if="PV.loading" type="image"></v-skeleton-loader>
        <v-card v-else elevation="0" outlined class="fill-height">
          <v-row class="fill-height">
            <v-col cols="12">
              <v-card-subtitle class="pb-0">與去年同期發電相比</v-card-subtitle>
              <v-card-text>
                <v-row align="baseline" justify="space-between">
                  <v-col cols="auto">
                    <div
                      v-if="PV.data"
                      :class="[PV.data.yoy > 0 ? 'green--text' : 'red--text']"
                      class="font-weight-bold text-h5"
                    >
                      {{ numeral(PV.data.yoy).format('+0%') }}
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-subtitle class="text-h6 accent--text pt-0">
                {{ numeral(PV.data.yoy * PV.data.monthlyEnergy).format('+0') }}
                <span class="subtitle-1 pl-1">kW</span>
              </v-card-subtitle>
            </v-col>
            <v-col cols="auto" align-self="center" class="ma-auto">
              <v-card-text>
                <v-progress-circular
                  :rotate="270"
                  :size="200"
                  :width="20"
                  :value="PV.data.yoy * 100 * (PV.data.yoy > 0 ? 1 : -1)"
                  :color="PV.data.yoy >= 0 ? 'green' : 'red'"
                  class="mt-2"
                >
                  <v-row class="text--secondary">
                    <v-col cols="12" class="text-center pb-0">
                      <div>佔去年比例的</div>
                    </v-col>
                    <v-col cols="12" class="text-center pt-0">
                      <div class="text-h4 font-weight-bold">
                        {{
                          numeral(
                            PV.data.yoy * (PV.data.yoy > 0 ? 1 : -1)
                          ).format('0.0%')
                        }}
                      </div>
                    </v-col>
                  </v-row>
                </v-progress-circular>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Meter from '@/api/ems/Meter'
import { countdownReload } from '@/shared/utils'

export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {
    id: {
      type: String,
      required: true
    },
    schoolId: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      timer: null,
      PV: {
        loading: false,
        data: 1234
      }
    }
  },
  computed: {
    thePower() {
      return this.PV.data.power > this.PV.data.meterCapacity
        ? this.PV.data.capacityPower
        : this.PV.data.power
    }
  },
  watch: {},
  created: function () {},
  mounted: function () {
    this.init()
    this.timer = countdownReload(this.init, 60)
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    init() {
      this.getPVMeter()
    },
    async getPVMeter() {
      this.PV.loading = true
      this.PV.data = null
      try {
        const { data } = await Meter.getPVMeter(this.id)
        this.PV.data = data
      } catch (error) {
        console.error(error)
      } finally {
        setTimeout(() => {
          this.PV.loading = false
        }, 500)
      }
    }
  }
}
</script>

<style></style>
