<template>
  <div>
    <div>PM2.5 (μg/m<sup>3</sup> )</div>
    <div
      v-if="pm25Info"
      class="font-weight-bold text-center mb-2 mt-1 pm25-circle"
      :style="{
        background: pm25Info.color,
        color: pm25Info.name === '普通' ? '#082e62' : '#fff'
      }"
    >
      {{ numeral(value).format('0') }}
    </div>
    <div v-else class="font-weight-bold error--text">維護中</div>
  </div>
</template>

<script>
export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {
    value: {
      type: [Number, String],
      require: true
    }
  },
  data: function () {
    return {
      pm25InfoItem: [
        {
          min: 0,
          max: 15.5,
          name: '良好',
          color: '#009865'
        },
        {
          min: 15.5,
          max: 35.5,
          name: '普通',
          color: '#fffb26'
        },
        {
          min: 35.5,
          max: 54.5,
          name: '對敏感族群不健康',
          color: '#ff9835'
        },
        {
          min: 54.5,
          max: 150.5,
          name: '對所有族群不健康',
          color: '#ca0034'
        },
        {
          min: 150.5,
          max: 250.5,
          name: '非常不健康',
          color: '#670099'
        },
        {
          min: 250.5,
          max: 500.4,
          name: '危害',
          color: '#7e0123'
        }
      ]
    }
  },
  computed: {
    pm25Info() {
      if (!this.value && this.value !== 0) return false
      else
        return this.pm25InfoItem.find(
          (item) => this.value >= item.min && this.value < item.max
        )
    }
  },
  watch: {},
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.pm25-circle {
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  border-radius: 50%;
}
*::v-deep {
  .PM25-Good {
    color: #009865;
  }
  .PM25-Moderate {
    color: #fffb26;
  }
  .PM25-Unhealthy-for-Sensitive-Groups {
    color: #ff9835;
  }
  .PM25-Unhealthy {
    color: #ca0034;
  }
  .PM25-Very-Unhealthy {
    color: #670099;
  }
  .PM25-Hazardous {
    color: #7e0123;
  }
}
</style>
